<template>
    <BT-Blade-Item
        :bladesData="bladesData"
        bladeName="courier-movement"
        :loadingMsg="loadingMsg"
        navigation="courier-movements"
        :onPullSuccessAsync="pullMovement"
        :refreshToggle="refreshToggle"
        title="Movement"
        :trackIgnore="['packagedItems']">
        <template v-slot="{ item, data }">
            <v-row no-gutters>
                <!-- <v-col cols="12">
                    <BT-Field-Company :id="item.buyerID" :title="item.buyer.companyName" />
                </v-col> -->

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        label="PICKUP FROM"
                        :value="item.departureLocation" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Address
                        v-if="!item.isPickup"
                        label="DELIVER TO"
                        :value="item.destinationLocation" />
                </v-col>
                
                <v-col cols="6" sm="4" class="d-flex align-center">
                    <BT-Field-Checkbox
                        v-if="!item.isPickup"
                        v-model="item.isAutoDeliver"
                        label="Auto Deliver"
                        :isEditing="data.isEditing" />
                    <div v-else class="warning--text">**PICKING UP</div>
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        :isEditing="data.isNew || data.isEditing"
                        label="Due Departure On"
                        shortDateAndTime
                        v-model="item.dueDepartureOn" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Date
                        :isEditing="data.isNew || data.isEditing"
                        label="Due Arrival On"
                        shortDateAndTime
                        v-model="item.dueArrivalOn" />
                </v-col>

                <v-col cols="6" sm="4">
                    <BT-Field-Select
                        v-if="!item.isPickup" 
                        v-model="item.journeyID"
                        navigation="journeys"
                        itemText="journeyName"
                        label="Journey"
                        :isEditing="data.isEditing" />
                </v-col>

                <v-col cols="6" sm="8">
                    <v-list-item dense>
                        <v-list-item-content>
                            <v-list-item-subtitle>Status</v-list-item-subtitle>
                            <v-list-item-title v-if="item.isFulfilled">Completed</v-list-item-title>
                            <v-list-item-title v-else-if="!item.isPickup && item.deliveredOn != null">Delivered</v-list-item-title>
                            <v-list-item-title v-else-if="item.releasedOn != null">{{ item.isPickup ? 'Picked Up' : 'On Its Way' }}</v-list-item-title>
                            <v-list-item-title v-else-if="item.isPickup">Ready</v-list-item-title>
                            <v-list-item-title v-else-if="item.journeyID == null">Needs a Journey</v-list-item-title>
                            <v-list-item-title v-else>Pending</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-col>
                <v-col cols="6" sm="4">
                    <BT-Btn
                        v-if="!item.isFulfilled && (item.isPickup && item.releasedOn != null || item.deliveredOn != null)"
                        block
                        buttonClass="primary mr-4"
                        @click="fulfill(item)"
                        icon="mdi-check-circle-outline"
                        label="Manually Fulfill" />
                </v-col>

                <v-col v-if="item.releasedOn == null" cols="12" sm="6">
                    <v-divider class="my-2" />
                    <BT-Btn
                        class="primary"
                        @click="release(item)"
                        block
                        height="75%"
                        icon="mdi-clipboard-arrow-right-outline"
                        label="Release" />
                </v-col>
                <v-col v-else cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-draw</v-icon>
                        Proof Of Release
                        <v-spacer />
                        <BT-Btn
                            v-if="item.releasedOn != null && !item.isPickup && item.deliveredOn == null"
                            @click="undoRelease(item)"
                            leftIcon="mdi-undo"
                            inline
                            label="Undo"
                            small
                            text />
                    </v-subheader>
                    <BT-Signature
                        class="ml-4 mb-1"
                        height="150px"
                        v-model="item.proofOfRelease" />
                    <span class="ml-4 text-caption">
                        <span>By</span>
                        <BT-Entity
                            navigation="public-users"
                            inline
                            :itemValue="item.releasedByUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache />
                        <span>at {{ item.releasedOn | toShortDateAndTime }}</span>
                    </span>
                </v-col>
                <v-col v-if="item.deliveredOn == null" cols="12" sm="6">
                    <v-divider class="my-2" />
                    <BT-Btn
                        class="primary"
                        @click="deliver(item)"
                        block
                        :disabled="item.releasedOn == null || item.isPickup"
                        height="75%"
                        icon="mdi-truck-delivery"
                        label="Deliver" />
                </v-col>
                <v-col v-else cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-draw</v-icon>
                        Proof Of Delivery
                    </v-subheader>
                    <BT-Signature
                        class="ml-4 mb-1"
                        height="150px"
                        v-model="item.proofOfDelivery" />
                    <span class="ml-4 text-caption">
                        <span>By</span>
                        <BT-Entity
                            navigation="public-users"
                            inline
                            :itemValue="item.deliveredByUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache />
                        <span>at {{ item.deliveredOn | toShortDateAndTime }}</span>
                    </span>
                </v-col>

                <v-col v-if="item.deliveredOn != null" cols="12" sm="4">
                    <v-divider class="my-2" />
                    <v-subheader>
                        <v-icon left small>mdi-camera</v-icon>
                        Photo
                        <v-spacer />
                        <BT-Btn
                            v-if="item.lat != null && item.lng != null"
                            :href="`https://maps.google.com/?q=${item.lat},${item.lng}`"
                            inline
                            label="Check Map"
                            leftIcon="mdi-map-marker-radius"
                            small
                            target="_blank" />
                    </v-subheader>
                    <BT-Photo
                        height="150px"
                        :icon="null"
                        :id="item.id" />
                </v-col>

                <v-col cols="12">
                    <BT-Field-Text-Area
                        v-if="item.driverNotes != null"
                        v-model="item.driverNotes"
                        label="Driver Notes" />
                    <v-divider class="my-4" />
                </v-col>

                <v-col cols="12">
                    <BT-Table
                        :canRefresh="false"
                        class="mt-4"
                        :headers="[
                            { text: 'Packages', value: 'measurement', display: true, title: 1 },
                            { text: 'Packed', value: 'quantityPacked', textFilter: 'toDisplayNumber', subtitle: 1, prefix: 'Packed: ' },
                            { text: 'Released', value: 'quantityReleased', textFilter: 'toDisplayNumber', subtitle: 2, prefix: 'Released: ' },
                            { text: 'Delivered', value: 'quantityDelivered', textFilter: 'toDisplayNumber', subtitle: 3, prefix: 'Delivered: ' },
                            { text: 'Received', value: 'quantityReceived', textFilter: 'toDisplayNumber', subtitle: 4, prefix: 'Received: ' }]"
                        hideActions
                        :items="item.packagedItems">
                        <template v-slot:actions="{ open }">
                            <BT-Snack v-model="msg" />
                            <BT-Packages-Movement-Dialog
                                v-if="open && item.releasedOn == null"
                                v-model="item.packagedItems"
                                icon-only
                                packed
                                small
                                @ok="packages => { savePackages(packages, item) }" />
                        </template>
                        <template v-slot:measurement="{ item }">
                            <BT-Entity 
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementName" />
                        </template>
                        <template v-slot:listItem="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <BT-Entity 
                                        navigation="measurements"
                                        :itemValue="item.measurementID"
                                        itemText="measurementName" />
                                </v-list-item-title>
                                <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Packed: {{ item.quantityPacked | toDisplayNumber }} | Released: {{ item.quantityReleased | toDisplayNumber }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Delivered: {{ item.quantityDelivered | toDisplayNumber }} | Received: {{ item.quantityReceived | toDisplayNumber }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Table>
                </v-col>
                <v-col cols="12">
                    <v-divider class="my-4" />
                    <BT-Preload
                        label="Show Consignment Info">
                        <v-subheader>Consignments</v-subheader>
                        <BT-Blade-Items
                            addBladeName="customer-consignment"
                            :canExportCSV="false"
                            :customURL="'/get/GetConsignments/' + item.id"
                            :headers="[
                                { text: 'CO#', value: 'consignmentNumber', subtitle: 3, prefix: 'CO# ' },
                                { text: 'Departure', value: 'departureLocation', textFilter: 'toFamiliarLocationLine', subtitle: 1, prefix: 'From: ' },
                                { text: 'Destination', value: 'destinationLocation', textFilter: 'toFamiliarLocationLine', subtitle: 2, prefix: 'To: ' },
                                { text: 'Weight (Kg)', value: 'orderWeight', textFilter: 'toDisplayNumber', subtitle: 4, prefix: 'Weight (Kg): ' }]"
                            hideActions
                            hideBladeHeader
                            hideHeader
                            navigation="releases"
                            :params="{ includeDetails: false }"
                            title="Consignments">
                        </BT-Blade-Items>
                    </BT-Preload>
                </v-col>
            </v-row>
            
            <!-- <v-list-item v-if="item.buyerID == item.courierID && item.receivedByUserID != null">
                <v-list-item-content>
                    <v-list-item-subtitle>Received By</v-list-item-subtitle>
                    <v-list-item-title>
                        <BT-Entity
                            navigation="users"
                            itemText="userName"
                            :itemValue="item.receivedByUserID" />
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="item.buyerID == item.courierID && item.deliveredOn != null && !item.isPickup">
                <v-list-item-content>
                    <BT-Blade-Button
                        label="Receive"
                        icon="mdi-clipboard-arrow-down-outline"
                        @click="receive(item)" />
                </v-list-item-content>
            </v-list-item> -->

        </template>
    </BT-Blade-Item>

</template>

<script>
import { firstBy } from 'thenby';
import { measurementArea } from '~helpers';

export default {
    name: 'Movement-Blade',
    components: {
        BTPackagesMovementDialog: () => import('~components/BT-Packages-Movement-Dialog.vue'),
        BTPhoto: () => import('~components/BT-Photo.vue'),
        BTSignature: () => import('~components/BT-Signature.vue'),
        BTTable: () => import('~components/BT-Table.vue')
    },
    data: function() {
        return {
            loadingMsg: null,
            msg: null,
            refreshToggle: false
        }
    },
    props: {
        bladesData: null
    },
    methods: {
        async deliver(item) {
            try {
                this.loadingMsg = 'Delivering';
                
                await this.$BlitzIt.store.patch('deliveries', { id: item.id, rowVersion: item.rowVersion, deliverAsIs: true });
                
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async fulfill(item) {
            try {
                this.loadingMsg = 'Fulfilling';
                await this.$BlitzIt.store.patch('courier-movements', { id: item.id, rowVersion: item.rowVersion, isFulfilledRequest: true });
                item.isFulfilled = true;
                this.$BlitzIt.store.deleteLocal('courier-movements', item.id);
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        pullMovement(item) {
            if (item.packagedItems != null) {
                item.packagedItems.sort(firstBy(x => measurementArea(x)));
            }

            return item;
        },
        async receive(item) {
            try {
                this.loadingMsg = 'Receiving';
                await this.$BlitzIt.store.patch('receivals', { id: item.id, rowVersion: item.rowVersion, receiveAsIs: true });
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async release(item) {
            try {
                this.loadingMsg = 'Releasing';
                await this.$BlitzIt.store.patch('releases', { id: item.id, rowVersion: item.rowVersion, releaseAsIs: true });
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async savePackages(packages, consignment) {
            try {
                this.loadingMsg = 'Saving Packages';

                consignment.packagedItems = packages;
                consignment.packagedItems.sort(firstBy(x => measurementArea(x)));

                await this.$BlitzIt.store.patch('releases', consignment);
                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async undoRelease(item) {
            try {
                this.loadingMsg = 'Undoing';

                item.proofOfRelease = null;

                await this.$BlitzIt.store.patch('releases', item);

                this.refreshToggle = !this.refreshToggle;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }

}
</script>